<template>
  <b-modal
    ref="list-modal"
    id="list-modal"
    v-model="show"
    :hide-footer="true"
    :hide-header="true"
  >
    <div class="card card-custom">
      <div class="card-header">
        <h3 class="card-title">
          {{ newList ? "Create List" : "Update List" }}
        </h3>

        <div class="symbol symbol-50 mr-1">
          <span class="symbol-label">
            <img
              :src="picture"
              class="h-50 align-self-center"
              style="margin-top: 10px"
              alt=""
            />
          </span>
        </div>
      </div>

      <!-- <form> -->
      <div class="card-body">
        <div class="form-group mb-8"></div>
        <b-form-group>
          <label>List Name <span class="text-danger">*</span></label>
          <b-form-input
            id="list-name-input"
            class="form-control"
            v-model="list.name"
            @keypress="keyPressed"
            :state="isListNameValid"
          />
        </b-form-group>
        <!-- <div class="form-group">
            <label>Colour <span class="text-danger">*</span></label>
            <input class="form-control" v-model="list.colour" />
          </div> -->
      </div>
      <div
        class="card-footer"
        style="display: flex; justify-content: flex-end;"
      >
        <button type="cancel" class="btn btn-primary mr-2" @click="cancel">
          Cancel
        </button>
        <button
          type="submit"
          class="btn btn-primary mr-2"
          :disabled="!canSubmit()"
          @click="submitList"
        >
          Submit
        </button>
      </div>
      <!-- </form> -->
    </div>
  </b-modal>
</template>
<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "list-edit-modal",
  props: ["newList", "list"],
  data() {
    return {
      show: false,
    };
  },
  computed: {
    picture() {
      return (
        process.env.BASE_URL + "media/svg/avatars/avatar-single-orange.svg"
      );
    },

    isListNameValid() {
      return !!this.list.name
        ? !(this.list.name.length > 0)
          ? false
          : null
        : false;
    },
  },
  methods: {
    cancel() {
      this.show = false;
    },

    keyPressed(e) {
      if (e.keyCode === 13) {
        if (this.canSubmit()) {
          this.submitList();
        }
      }
    },

    canSubmit() {
      if (this.list.name === "") {
        return false;
      } else {
        return true;
      }
    },

    submitList() {
      //TEMP FIX TO FORCE A COLOUR
      this.list.colour = "Red";

      if (this.newList) {
        ApiService.post(`lists`, this.list)
          .then((res) => {
            this.$emit("listsUpdated");
            this.$refs["list-modal"].hide();
          })
          .catch((error) => {
            console.log("Error saving list", error);
          });
      } else {
        ApiService.put(`lists`, this.list)
          .then((res) => {
            this.$emit("listsUpdated");
            this.$refs["list-modal"].hide();
          })
          .catch((error) => {
            console.log("Error saving list", error);
          });
      }
    },
  },
};
</script>
