<template>
  <div
    class="card card-custom gutter-b card-stretch card-shadowless bg-light vh-90"
  >
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          List Contacts - {{ this.list ? this.list.name : "" }}
        </span>
      </h3>
      <div class="card-toolbar">
        <!-- <ul class="nav nav-pills nav-pills-sm nav-dark-75">
            <li class="nav-item">
              <a
                class="nav-link py-2 px-4 active"
                data-toggle="tab"
                href="#kt_tab_pane_1_1"
              >
                Month
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link py-2 px-4"
                data-toggle="tab"
                href="#kt_tab_pane_1_2"
              >
                Week
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link py-2 px-4"
                data-toggle="tab"
                href="#kt_tab_pane_1_3"
              >
                Day
              </a>
            </li>
          </ul> -->
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-borderless table-vertical-center">
          <thead>
            <tr>
              <th class="p-0" style="width: 50px"></th>
              <th class="p-0" style="min-width: 100px"></th>
              <th class="p-0" style="min-width: 100px"></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in listContacts">
              <tr v-bind:key="i">
                <td class="pl-0 py-4">
                  <div class="symbol symbol-50 symbol-light mr-1">
                    <span class="symbol-label">
                      <img
                        :src="picture"
                        class="h-50 align-self-center"
                        alt=""
                      />
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >
                    {{ formatContactName(item) }}
                  </a>
                  <br />
                  <span class="text-muted">
                    {{
                      item.mobileNumber.slice(0, 4) +
                        " " +
                        item.mobileNumber.slice(4, 7) +
                        " " +
                        item.mobileNumber.slice(7)
                    }}
                  </span>
                </td>
                <td class="text-right pr-0">
                  <!-- <a href="#" class="btn btn-icon btn-light btn-sm">
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <inline-svg
                              src="media/svg/icons/General/Settings-1.svg"
                            ></inline-svg>
                          </span>
                        </a> -->
                  <span class="d-inline-block" tabindex="0">
                    <b-link
                      href="#"
                      class="btn btn-icon btn-light btn-sm"
                      @click="removeContactFromList(item)"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon-->
                        <inline-svg
                          src="media/svg/icons/General/Trash.svg"
                        ></inline-svg>
                        <!--end::Svg Icon-->
                      </span>
                    </b-link>
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "list-contacts-widget",
  props: ["list", "addContact"],
  data() {
    return {
      listContacts: [],
      snackbar: false,
      snackbarText: "",
      timeout: 4000,
    };
  },
  computed: {
    picture() {
      return (
        process.env.BASE_URL + "media/svg/avatars/avatar-single-orange.svg"
      );
    },
  },
  methods: {
    getListContacts() {
      ApiService.query(`lists/${this.list.id}`).then((response) => {
        this.listContacts = response.data.contacts.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
      });
    },
    formatContactName(contact) {
      return contact.firstName
        ? contact.firstName + " " + contact.surname
        : contact.surname;
    },
    removeContactFromList(contact) {
      ApiService.delete(`lists/${this.list.id}/contacts/${contact.id}`).then(
        (response) => {
          this.getListContacts();
        }
      );
    },
    addContactToList() {
      ApiService.put(
        `lists/${this.list.id}/contacts/${this.addContact.id}`
      ).then((response) => {
        this.getListContacts();
      });
    },
  },
  watch: {
    list() {
      if (!!this.list) {
        this.getListContacts();
      }
    },
    addContact() {
      if (this.addContact) {
        if (
          this.listContacts.find((x) => x.id == this.addContact.id) ||
          this.listContacts.find((x) => x.id == this.addContact.itemId)
        ) {
          this.snackbarText = "Contact is already in your list";
          this.snackbar = true;
        } else {
          this.addContactToList();
        }
      }
    },
  },
};
</script>
