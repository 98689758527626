<template>
  <div class="card card-custom gutter-b card-stretch card-shadowless bg-light">
    <ListEditModal
      :list="selectedList"
      :newList="newList"
      @listsUpdated="getLists"
    ></ListEditModal>
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          My Lists
        </span>

        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          These are your lists.<br />
          Others can see these but only you can edit them.
        </span>
      </h3>
      <div class="card-toolbar">
        <!-- <ul class="nav nav-pills nav-pills-sm nav-dark-75">
            <li class="nav-item">
              <a
                class="nav-link py-2 px-4 active"
                data-toggle="tab"
                href="#kt_tab_pane_1_1"
              >
                Month
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link py-2 px-4"
                data-toggle="tab"
                href="#kt_tab_pane_1_2"
              >
                Week
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link py-2 px-4"
                data-toggle="tab"
                href="#kt_tab_pane_1_3"
              >
                Day
              </a>
            </li>
          </ul> -->
        <!-- <b-link href="#" @click="addList" class="btn btn-icon btn-light btn-sm">
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg
              src="media/svg/icons/Communication/Add-list.svg"
            ></inline-svg>
          </span>
        </b-link> -->
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-borderless table-vertical-center">
          <thead>
            <tr>
              <th class="p-0" style="width: 50px"></th>
              <th class="p-0" style="min-width: 100px"></th>
              <th class="p-0" style="min-width: 100px"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td class="text-right pr-0">
                <b-link
                  href="#"
                  @click="addList"
                  class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >Add list
                </b-link>
                <b-link
                  href="#"
                  @click="addList"
                  class="btn btn-icon btn-light btn-sm"
                >
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <inline-svg
                      src="media/svg/icons/Communication/Add-list.svg"
                    ></inline-svg>
                  </span>
                </b-link>
              </td>
            </tr>
            <template v-for="(item, i) in lists">
              <tr v-bind:key="i">
                <td class="pl-0 py-4">
                  <div class="symbol symbol-50 symbol-light mr-1">
                    <span class="symbol-label">
                      <img
                        :src="picture"
                        class="h-50 align-self-center"
                        alt=""
                      />
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >
                    {{ item.name }}
                  </a>
                </td>
                <td class="text-right pr-0">
                  <!-- <a href="#" class="btn btn-icon btn-light btn-sm">
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <inline-svg
                              src="media/svg/icons/General/Settings-1.svg"
                            ></inline-svg>
                          </span>
                        </a> -->
                  <b-link
                    href="#"
                    @click="manageList(item)"
                    class="btn btn-icon btn-light btn-sm"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg
                        src="media/svg/icons/Communication/Manage-contacts.svg"
                      ></inline-svg>
                    </span>
                  </b-link>

                  <a
                    href="#"
                    class="btn btn-icon btn-light btn-sm mx-3"
                    @click="editList(item)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Communication/Write.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <span class="d-inline-block" tabindex="0">
                    <b-link
                      href="#"
                      class="btn btn-icon btn-light btn-sm"
                      @click="deleteList(item)"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon-->
                        <inline-svg
                          src="media/svg/icons/General/Trash.svg"
                        ></inline-svg>
                        <!--end::Svg Icon-->
                      </span>
                    </b-link>
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import ListEditModal from "@/view/content/widgets/lists/ListEditModal.vue";

export default {
  name: "lists-widget",
  components: {
    ListEditModal,
  },
  data() {
    return {
      lists: [],
      selectedList: {},
      newList: false,
    };
  },
  computed: {
    picture() {
      return (
        process.env.BASE_URL + "media/svg/avatars/avatar-single-orange.svg"
      );
    },
  },
  methods: {
    deleteList(list) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want to delete ${list.name}?`, {
          autoFocusButton: "ok",
        })
        .then((value) => {
          if (value === true) {
            ApiService.delete(`lists/${list.id}`).then((response) => {
              this.$emit("listDeleted", list);
              this.getLists();
            });
          } else {
            console.log("User cancelled deletion");
          }
        })
        .catch((err) => {});
    },

    getLists() {
      ApiService.query("lists").then((response) => {
        this.lists = response.data.sort((a, b) => (a.name > b.name ? 1 : -1));
      });
    },

    editList(list) {
      ApiService.get(`lists/${list.id}`).then((res) => {
        this.selectedList = res.data;
        this.newList = false;
        this.$bvModal.show("list-modal");
      });
    },

    addList() {
      this.newList = true;
      this.selectedList = {
        name: "",
        colour: "",
        contacts: null,
      };
      this.$bvModal.show("list-modal");
    },

    manageList(list) {
      this.$emit("listSelected", list);
    },
  },

  mounted() {
    this.getLists();
  },
};
</script>
