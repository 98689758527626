<template>
  <div>
    <b-row>
      <b-col lg="4">
        <ListsWidget
          @listSelected="manageList"
          @listDeleted="closeListIfDeleted"
        ></ListsWidget>
        <!-- <div class="row">
      <div class="col-md-12">
        <b-table striped hover :items="contacts"></b-table>
      </div>
    </div> -->
      </b-col>
      <b-col lg="4" v-show="this.selectedList">
        <ListContactsWidget
          :list="selectedList"
          :addContact="this.addedContact"
        ></ListContactsWidget>
      </b-col>
      <b-col lg="4" v-show="this.selectedList">
        <ListContactSelectorWidget
          @contactAdded="addContact"
        ></ListContactSelectorWidget>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ListsWidget from "@/view/content/widgets/lists/ListsWidget.vue";
import ListContactsWidget from "@/view/content/widgets/lists/ListContactsWidget.vue";
import ListContactSelectorWidget from "@/view/content/widgets/lists/ListContactSelectorWidget.vue";

export default {
  name: "manage-lists",
  data() {
    return {
      selectedList: null,
      addedContact: null,
    };
  },

  components: {
    ListsWidget,
    ListContactsWidget,
    ListContactSelectorWidget,
  },

  computed: {},

  methods: {
    manageList(list) {
      this.selectedList = list;
    },
    closeListIfDeleted(list) {
      if (this.selectedList == list) {
        this.selectedList = null;
      }
    },
    addContact(contact) {
      this.addedContact = JSON.parse(JSON.stringify(contact));
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" },
    ]);

    this.selectedContact = new Object({
      firstName: "",
      surname: "",
      mobileNumber: "",
    });
  },
};
</script>
